<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="2" md="2">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="430px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                label="Date or Date range"
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                @click:prepend="menu = true"
                readonly
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
            ></v-text-field>

          </template>
          <v-card flat>
            <v-row no-gutters>
              <v-col cols="4">

                <v-list dense>
                  <!--                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>-->
                  <v-subheader class="justify-center">
                    <v-icon>mdi-calendar-range-outline</v-icon>
                  </v-subheader>
                  <v-list-item-group
                      v-model="selectedFastDateRange"
                      color="primary"
                  >
                    <v-list-item
                        v-for="(item, i) in selectedFastDateRangeItems"
                        :key="i"
                    >
                      <!--                        <v-list-item-icon>-->
                      <!--                          <v-icon v-text="item.icon"></v-icon>-->
                      <!--                        </v-list-item-icon>-->
                      <v-list-item-content @click="onClickFastDateInterval(item.value)">
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>

                </v-list>
              </v-col>
              <v-col cols="8">
                <v-date-picker
                    v-model="dates"
                    :show-current="todayDate()"
                    reactive
                    first-day-of-week="1"
                    range
                ></v-date-picker>
                <!--                  <v-spacer></v-spacer>-->
                <div class="text-right">
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(dates);onclickOkPeekDate()"
                  >
                    OK
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

        </v-menu>
      </v-col>
      <v-col cols="12" sm="1" md="1">
        <v-select
            v-model="sumBy"
            :items="['Day', 'Total']"
            hide-details
            dense
            label="Sum"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <v-select
            v-model="groupBy"
            :items="['Who added', 'Creator']"
            hide-details
            dense
            label="Group by"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2"
             v-if="groupBy === 'Who added'">
        <v-select
            v-model="whoAdd"
            :items="whoAdded"
            hide-details
            dense
            label="Who added"
            clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2"
             v-if="groupBy === 'Creator'"
      >
        <v-select
            v-model="creator"
            :items="creators"
            hide-details
            label="Creator"
            dense
            clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-col>
      <v-col cols="12" offset-sm="1" offset-md="1" sm="1" md="1">
        <v-select
            class="pt-1 mr-0"
            v-model="itemsPerPage"
            :items="itemsPerPageOptions"
            hide-details
            dense
            solo
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="pt-0">
        <v-data-table
            :headers="headers"
            :items="statistic"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :page.sync="page"
            :loading="loading"
            :server-items-length="total"
            :options.sync="options"
            @page-count="pageCount = $event"
            @update:sort-desc="onUpdateSortTable"
            multi-sort
            hide-default-footer
            dense
        >
          <template slot="body.prepend">
            <tr class="primary--text">
              <th v-if="sumBy === 'Day'"></th>
              <th></th>
              <th>{{ totalCampaigns }}</th>
            </tr>
          </template>

          <template v-slot:item.optimization_date="{ item }" v-if="sumBy === 'Day'">
            {{ dateFormat(item.optimization_date) }}
          </template>
          <!--      <template v-slot:item.id="{ item }">-->
          <!--        <a target="_blank" rel="noopener noreferrer"-->
          <!--           :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"-->
          <!--        >{{ item.id}}-->
          <!--        </a>-->
          <!--      </template>-->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/utils/api'
import { DateTime } from 'luxon'
// import { IFACE_SPENDING_LAST_UPDATE } from '@/store/actions/iface'

export default {
  name: 'OptimizationStatistic',
  data () {
    return {
      tz: 'America/Los_Angeles',
      menu: false,

      sumBy: 'Day',
      groupBy: null,
      dates: [],

      options: {itemsPerPage: 20},

      sortBy: ['optimization_date', 'count'],
      sortDesc: [true, true],

      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],

      headers: [],
      statistic: [],
      total: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      loading: false,

      totalCampaigns: 0,

      selectedFastDateRange: 0,
      selectedFastDateRangeItems: [
        {text: 'Today', value: 'today'},
        {text: 'Yesterday', value: 'yesterday'},
        {text: 'This month', value: 'thisMonth'},
        {text: 'Last month', value: 'lastMonth'},
      ],

      whoAdd: null,
      whoAdded: [],

      creator: null,
      creators: []
    }
  },

  async created () {
    const date = this.todayDate()
    this.dates = [date, date]
    this.groupBy = 'Who added'
  },

  async mounted () {
    this.getDataFromApi()
  },

  computed: {
    dateRangeText () {
      if (this.isToday()) return 'Today'
      if (this.isYesterday()) return 'Yesterday'
      if (this.isThisMonth()) return 'This month'
      if (this.isLastMonth()) return 'Last month'
      return this.dates.join(' ~ ')
    },
  },

  methods: {
    todayDate () {
      // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
      // console.log('cccccccccccc', c, )
      return DateTime.local().setZone(this.tz).toISODate()
    },

    yesterdayDate () {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
    },

    thisMonth () {
      return DateTime.local().setZone(this.tz).startOf('month').toISODate()
    },

    isToday () {
      if (this.dates.length === 1)
        return this.todayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

      return false
    },

    isYesterday () {
      if (this.dates.length === 1)
        return this.yesterdayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

      return false
    },

    isThisMonth () {
      if (this.dates.length === 2)
        return this.thisMonth() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isLastMonth () {
      const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
      const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

      if (this.dates.length === 2)
        return startMonth === this.dates[0] && endMonth === this.dates[1]
      return false
    },

    onClickFastDateInterval (value) {
      switch (value) {
        case 'today' :
          this.dates = [this.todayDate()]
          break
        case 'yesterday' :
          this.dates = [this.yesterdayDate()]
          break
        case 'thisMonth' :
          this.dates = [this.thisMonth(), this.todayDate()]
          break
        case 'lastMonth' :
          this.dates = [
            DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]
          break
        default:
          throw `Unknown ${value}`
      }
    },

    dateFormat (unixtime) {
      if (typeof unixtime === 'number')
        return DateTime.fromSeconds(unixtime).toLocaleString({...DateTime.DATE_MED})
    },

    setHeaders (sumBy, groupBy) {
      const headers = []

      if (sumBy === 'Day') headers.push({text: 'Date', value: 'optimization_date'},)

      if (groupBy === 'Who added') {
        headers.push(
            {text: 'Who added', value: 'who_added'},
            {text: 'Count', value: 'count'},)

      }
      if (groupBy === 'Creator') {
        headers.push(
            {text: 'Creator', value: 'creator'},
            {text: 'Count', value: 'count'}
        )

      }
      this.$set(this, 'headers', headers)
    },

    onclickOkPeekDate () {
      this.getDataFromApi()
    },

    onUpdateSortTable () {
      this.getDataFromApi()
    },

    async getDataFromApi () {
      this.loading = true

      let startDate
      let endDate

      if (this.dates.length === 1) {
        startDate = this.dates[0]
        endDate = this.dates[0]
      } else if (this.dates.length === 2) {
        startDate = this.dates[0]
        endDate = this.dates[1]
      } else
        throw 'Error dates'

      const sortBy = this.sortBy.length > 0 ? this.sortBy : undefined
      const sortDesc = this.sortDesc.length > 0 ? this.sortDesc : undefined

      const r = {
        sumBy: this.sumBy,
        groupBy: this.groupBy,
        limit: this.itemsPerPage,
        page: this.page - 1,
        startDate,
        endDate,
        sortBy,
        sortDesc
      }

      if (r.groupBy === 'Who added' && this.whoAdd) {
        r.whoAdded = this.whoAdd
      }
      if (r.groupBy === 'Creator' && this.creator) {
        r.creator = this.creator
      }

      try {
        const res = await api.getOptimizationStatistic(r)
        this.$set(this, 'total', res.data.total)
        this.$set(this, 'totalCampaigns', res.data.totalCampaigns)
        this.$set(this, 'statistic', res.data.statistic)
        if (r.groupBy === 'Who added') {
          this.$set(this, 'whoAdded', res.data.whoAdded)
        }
        if (r.groupBy === 'Creator') {
          this.$set(this, 'creators', res.data.creators)
        }
        console.info('getDataFromApi', res)
      } catch (e) {
        console.error('getDataFromApi', e)
      }
      this.loading = false
    },
  },

  watch: {
    page () {
      this.getDataFromApi()
    },

    itemsPerPage (value) {
      this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
      this.getDataFromApi()
    },

    groupBy (v) {
      this.sortBy = ['optimization_date', 'count']
      this.sortDesc = [true, true]
      this.setHeaders(this.sumBy, v)
      this.getDataFromApi()
    },

    whoAdd () {
      this.getDataFromApi()
    },
    creator () {
      this.getDataFromApi()
    },
    sumBy (v) {
      this.sortBy = ['count']
      this.sortDesc = [true]
      this.setHeaders(v, this.groupBy)
      this.getDataFromApi()
    }
  }
}
</script>

<style scoped>

</style>